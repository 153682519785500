import has from "lodash/has";

export const identifyUserAndAccount = ({ user, account }) => {
    if (has(window, "aptrinsic")) {
        window.aptrinsic(
            "identify",
            {
                ...user,
                screenResolution: `${window.screen.width}x${window.screen.height}`
            },
            account
        );
    }
};
